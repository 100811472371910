import React, { useState, useEffect } from "react";

/*eslint-disable*/

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";

import { FiInfo, FiCheckSquare } from "react-icons/fi";

import OfflineMessage from "../../../components/offline_message";

ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    BarElement,
    ArcElement,
    PointElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: "top",
        },
        title: {
            display: false,
            text: "Chart.js Bar Chart",
        },
    },
};

import { FormattedMessage, IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";
import SmallStatisticsBox from "../../../components/Charts/SmallStatisticsBox";

const messages = {
    en: messages_en,
    pt: messages_pt,
    fr: messages_fr,
};

// Utilities and configurations
import FastAPIClient from "../../../client";
import config from "../../../config";

const client = new FastAPIClient(config);

const useNetworkStatus = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [isSlowConnection, setIsSlowConnection] = useState(false);

    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        const handleConnectionChange = () => {
            const connection =
                navigator.connection ||
                navigator.mozConnection ||
                navigator.webkitConnection;
            if (connection) {
                setIsSlowConnection(
                    connection.effectiveType === "2g" ||
                        connection.effectiveType === "3g" ||
                        connection.downlink < 1.5
                );
            }
        };

        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);

        if (navigator.connection) {
            handleConnectionChange();
            navigator.connection.addEventListener(
                "change",
                handleConnectionChange
            );
        }

        return () => {
            window.removeEventListener("online", handleOnline);
            window.removeEventListener("offline", handleOffline);

            if (navigator.connection) {
                navigator.connection.removeEventListener(
                    "change",
                    handleConnectionChange
                );
            }
        };
    }, []);

    return { isOnline, isSlowConnection };
};

const TableWithProductMetrics = () => {
    const [data, setData] = useState([]);
    const [productLevels, setProductLevels] = useState([]);
    const { isOnline, isSlowConnection } = useNetworkStatus();

    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;

    useEffect(() => {
        const fetchData = async (userRoleId, warehouseId) => {
            if (!userRoleId) {
                console.error("UserRoleId is required to fetch stats");
                return;
            }

            try {
                // Fetch the data from the API
                const data = await client.getManagementDashboard(
                    userRoleId,
                    warehouseId
                );

                // Ensure `data.results` exists before filtering
                if (!data?.results) {
                    throw new Error("Missing results in API response");
                }

                // Filter the data by 'metric_name'
                const filteredData = data.results.filter(
                    (item) => item.metric_name === "Product Metrics"
                );

                // Map over filtered data to extract unique product levels
                const uniqueProductLevels = [
                    ...new Set(
                        filteredData
                            .map((item) => item.json_data?.product_level_5)
                            .filter((level) => level) // Filters out null or undefined values
                    ),
                ];

                // Update state with the unique product levels
                setProductLevels(uniqueProductLevels);
                setData(filteredData); // Optionally store the filtered data
            } catch (error) {
                // Handle errors, if any
                console.error("Error fetching data:", error);
            }
        };

        if (isOnline && !isSlowConnection) {
            client
                .fetchUser()
                .then((data) => {
                    if (
                        data &&
                        data.user_role_id &&
                        data.warehouse_id !== undefined
                    ) {
                        fetchData(data.user_role_id, data.warehouse_id);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching user details:", error);
                });
        }
    }, [isOnline, isSlowConnection]);

    return (
        <div className="w-full overflow-x-auto">
            <table
                className="w-3/4 border-collapse text-xs"
                style={{ tableLayout: "fixed" }}
            >
                <thead>
                    {/* Main Column Headers (dynamically generated) */}
                    <tr>
                        <th
                            className="sticky left-0 bg-white px-4 py-2 text-left z-[20]"
                            style={{ width: "400px" }} // Set first column width (twice as wide)
                            colSpan="1"
                        ></th>
                        {productLevels.map((level) => (
                            <>
                                <th
                                    key={`${level}-2023`}
                                    className="border-b border-r border-gray-300 px-4 py-2 text-center"
                                    colSpan="2"
                                    style={{ width: "200px" }}
                                >
                                    {level}
                                </th>
                            </>
                        ))}
                    </tr>
                    {/* Year Sub-headers */}
                    <tr>
                        <th
                            className="sticky left-0 bg-white border-b border-gray-300 px-4 py-2 text-left z-[20]"
                            style={{ width: "400px" }} // Set first column width (twice as wide)
                        ></th>
                        {productLevels.map((level) => (
                            <>
                                <th className="border-b border-gray-300 px-4 py-2 text-center">
                                    {previousYear}
                                </th>
                                <th className="border-b border-r border-gray-300 px-4 py-2 text-center">
                                    {currentYear}
                                </th>
                            </>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {/* Row for each metric */}
                    {[
                        { label: "CA généré (k€)", field: "ca_genere" },
                        {
                            label: "CA moyen par client (€)",
                            field: "ca_moyen_par_client",
                        },
                        { label: "Volume vendu (hL)", field: "volume_vendu" },
                        {
                            label: "Taux de marge (%)",
                            field: "taux_de_marge",
                        },
                        {
                            label: "DN total client (%)",
                            field: "dn_total_client",
                        },
                    ].map((metric) => (
                        <tr key={metric.label}>
                            <td
                                className="sticky left-0 bg-white border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left"
                                style={{ width: "400px" }} // Set first column width (twice as wide)
                            >
                                {metric.label}
                            </td>
                            {productLevels.map((level) => {
                                const productData = data.filter(
                                    (item) =>
                                        item.json_data.product_level_5 === level
                                );
                                const year2023Data =
                                    productData[0]?.json_data[
                                        `${metric.field}_2024`
                                    ] || "-";
                                const year2024Data =
                                    productData[0]?.json_data[
                                        `${metric.field}_2025`
                                    ] || "-";

                                return (
                                    <>
                                        <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                            {year2023Data}
                                        </td>
                                        <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                            {year2024Data}
                                        </td>
                                    </>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const TableWithClientMetrics = () => {
    const [data, setData] = useState([]);
    const [clientLevels, setClientLevels] = useState([]);
    const { isOnline, isSlowConnection } = useNetworkStatus();

    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;

    useEffect(() => {
        const fetchData = async (userRoleId, warehouseId) => {
            if (!userRoleId) {
                console.error("UserRoleId is required to fetch stats");
                return;
            }

            try {
                // Fetch the data from the API
                const data = await client.getManagementDashboard(
                    userRoleId,
                    warehouseId
                );

                // Ensure `data.results` exists before filtering
                if (!data?.results) {
                    throw new Error("Missing results in API response");
                }

                // Filter the data by 'metric_name'
                const filteredData = data.results.filter(
                    (item) => item.metric_name === "Client Metrics"
                );

                // Map over filtered data to extract unique product levels
                const uniqueClientLevels = [
                    ...new Set(
                        filteredData
                            .map((item) => item.json_data?.client_level_1)
                            .filter((level) => level) // Filters out null or undefined values
                    ),
                ];

                // Update state with the unique product levels
                setClientLevels(uniqueClientLevels);
                setData(filteredData); // Optionally store the filtered data
            } catch (error) {
                // Handle errors, if any
                console.error("Error fetching data:", error);
            }
        };

        if (isOnline && !isSlowConnection) {
            client
                .fetchUser()
                .then((data) => {
                    if (
                        data &&
                        data.user_role_id &&
                        data.warehouse_id !== undefined
                    ) {
                        fetchData(data.user_role_id, data.warehouse_id);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching user details:", error);
                });
        }
    }, [isOnline, isSlowConnection]);

    return (
        <div className="w-full overflow-x-auto">
            <table
                className="w-3/4 border-collapse text-xs"
                style={{ tableLayout: "fixed" }}
            >
                <thead>
                    {/* Main Column Headers (dynamically generated) */}
                    <tr>
                        <th
                            className="sticky left-0 bg-white px-4 py-2 text-left z-[20]"
                            style={{ width: "400px" }} // Set first column width (twice as wide)
                            colSpan="1"
                        ></th>
                        {clientLevels.map((level) => (
                            <>
                                <th
                                    key={`${level}-2023`}
                                    className="border-b border-r border-gray-300 px-4 py-2 text-center"
                                    colSpan="2"
                                    style={{ width: "200px" }}
                                >
                                    {level}
                                </th>
                            </>
                        ))}
                    </tr>
                    {/* Year Sub-headers */}
                    <tr>
                        <th
                            className="sticky left-0 bg-white border-b border-gray-300 px-4 py-2 text-left z-[20]"
                            style={{ width: "400px" }} // Set first column width (twice as wide)
                        ></th>
                        {clientLevels.map((level) => (
                            <>
                                <th className="border-b border-gray-300 px-4 py-2 text-center">
                                    {previousYear}
                                </th>
                                <th className="border-b border-r border-gray-300 px-4 py-2 text-center">
                                    {currentYear}
                                </th>
                            </>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {/* Row for each metric */}
                    {[
                        { label: "Nombre de clients", field: "clients" },
                        {
                            label: "Nombre de clients à risque",
                            field: "at_risk_clients",
                        },
                        {
                            label: "Taux de marge par client (%)",
                            field: "taux_de_marge",
                        },
                        {
                            label: "CA généré (k€)",
                            field: "avg_revenue",
                        },
                        { label: "Volume vendu (hL)", field: "avg_volume" },
                        {
                            label: "# moyen de catégories produits référencées par client",
                            field: "avg_unique_product_categories_per_client",
                        },
                        {
                            label: "# moyen de références par client",
                            field: "avg_unique_products_per_client",
                        },
                        {
                            label: "# moyen de catégories produits par facture",
                            field: "avg_unique_product_categories_per_receipt_per_client",
                        },
                        {
                            label: "# moyen de références par facture",
                            field: "avg_unique_products_per_receipt_per_client",
                        },
                        {
                            label: "Catégorie produit la plus fréquemment vendue",
                            field: "top_category",
                        },
                    ].map((metric) => (
                        <tr key={metric.label}>
                            <td
                                className="sticky left-0 bg-white border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left"
                                style={{ width: "400px" }} // Set first column width (twice as wide)
                            >
                                {metric.label}
                            </td>
                            {clientLevels.map((level) => {
                                const productData = data.filter(
                                    (item) =>
                                        item.json_data.client_level_1 === level
                                );
                                const year2023Data =
                                    productData[0]?.json_data[
                                        `${metric.field}_2024`
                                    ] || "-";
                                const year2024Data =
                                    productData[0]?.json_data[
                                        `${metric.field}_2025`
                                    ] || "-";

                                return (
                                    <>
                                        <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center">
                                            {year2023Data}
                                        </td>
                                        <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center">
                                            {year2024Data}
                                        </td>
                                    </>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const TableWithSalesRepMetrics = () => {
    const [data, setData] = useState([]);
    const [salesRepLevels, setSalesRepLevels] = useState([]);
    const { isOnline, isSlowConnection } = useNetworkStatus();

    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;

    useEffect(() => {
        const fetchData = async (userRoleId, warehouseId) => {
            if (!userRoleId) {
                console.error("UserRoleId is required to fetch stats");
                return;
            }

            try {
                // Fetch the data from the API
                const data = await client.getManagementDashboard(
                    userRoleId,
                    warehouseId
                );

                // Ensure `data.results` exists before filtering
                if (!data?.results) {
                    throw new Error("Missing results in API response");
                }

                // Filter the data by 'metric_name'
                const filteredData = data.results.filter(
                    (item) => item.metric_name === "Sales Metrics"
                );

                // Map over filtered data to extract unique product levels
                const uniqueSalesReps = [
                    ...new Set(
                        filteredData
                            .map((item) => item.json_data?.sales_rep_name)
                            .filter((level) => level) // Filters out null or undefined values
                    ),
                ];

                // Update state with the unique product levels
                setSalesRepLevels(uniqueSalesReps);
                setData(filteredData); // Optionally store the filtered data
            } catch (error) {
                // Handle errors, if any
                console.error("Error fetching data:", error);
            }
        };

        if (isOnline && !isSlowConnection) {
            client
                .fetchUser()
                .then((data) => {
                    if (
                        data &&
                        data.user_role_id &&
                        data.warehouse_id !== undefined
                    ) {
                        fetchData(data.user_role_id, data.warehouse_id);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching user details:", error);
                });
        }
    }, [isOnline, isSlowConnection]);

    return (
        <div className="w-full overflow-x-auto">
            <table
                className="w-full border-collapse text-xs table-auto"
                style={{ tableLayout: "fixed" }}
            >
                <thead>
                    {/* Main Column Headers (dynamically generated) */}
                    <tr>
                        <th
                            className="sticky left-0 bg-white px-4 py-2 text-left z-[20]"
                            style={{ width: "400px" }} // Apply width to first column header (twice as wide)
                            colSpan="2"
                        ></th>
                        {salesRepLevels.map((level) => (
                            <>
                                <th
                                    key={`${level}-2023`}
                                    className="border-b border-r border-gray-300 px-4 py-2 text-center min-w-[100px]" // Min width for better control
                                    colSpan="2"
                                    style={{ width: "200px" }}
                                >
                                    {level}
                                </th>
                            </>
                        ))}
                    </tr>
                    {/* Year Sub-headers */}
                    <tr>
                        <th
                            className="sticky left-0 bg-white border-b border-gray-300 px-4 py-2 text-left z-[20]"
                            style={{ width: "400px" }} // Apply width to first column header (twice as wide)
                            colSpan="2"
                        ></th>
                        {salesRepLevels.map((level) => (
                            <>
                                <th className="border-b border-gray-300 px-4 py-2 text-center min-w-[100px]">
                                    {previousYear}
                                </th>
                                <th className="border-b border-r border-gray-300 px-4 py-2 text-center min-w-[100px]">
                                    {currentYear}
                                </th>
                            </>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {/* Row for each metric */}
                    {[
                        { label: "Nombre de clients", field: "nombre_clients" },
                        { label: "CA généré (k€)", field: "ca_genere" },
                        {
                            label: "Taux de marge par client (%)",
                            field: "taux_de_marge",
                        },
                        { label: "Volume vendu (hL)", field: "volume_vendu" },
                        {
                            label: "# moyen de catégories produits référencées par client",
                            field: "avg_unique_product_categories_per_client",
                        },
                        {
                            label: "# moyen de références par client",
                            field: "avg_unique_products_per_client",
                        },
                        {
                            label: "# moyen de catégories produits par facture",
                            field: "avg_unique_product_categories_per_receipt_per_client",
                        },
                        {
                            label: "# moyen de références par facture",
                            field: "avg_unique_products_per_receipt_per_client",
                        },
                        {
                            label: "Catégorie produit la plus fréquemment vendue",
                            field: "top_category",
                        },
                        {
                            label: "DN total client (%)",
                            field: "dn_total_client",
                        },
                    ].map((metric) => (
                        <tr key={metric.label}>
                            <td
                                className="sticky left-0 bg-white border-b border-dashed font-bold border-gray-300 px-4 py-2 text-left"
                                style={{ width: "400px" }} // Apply width to first column data (twice as wide)
                                colSpan="2"
                            >
                                {metric.label}
                            </td>
                            {salesRepLevels.map((level) => {
                                const productData = data.filter(
                                    (item) =>
                                        item.json_data.sales_rep_name === level
                                );
                                const year2023Data =
                                    productData[0]?.json_data[
                                        `${metric.field}_2024`
                                    ] || "-";
                                const year2024Data =
                                    productData[0]?.json_data[
                                        `${metric.field}_2025`
                                    ] || "-";

                                return (
                                    <>
                                        <td className="border-b border-dashed border-gray-300 px-4 py-2 text-center min-w-[100px]">
                                            {year2023Data}
                                        </td>
                                        <td className="border-b border-dashed border-r border-gray-300 px-4 py-2 text-center min-w-[100px]">
                                            {year2024Data}
                                        </td>
                                    </>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const TableWithPromotionMetrics = () => {
    const [data, setData] = useState([]);
    const { isOnline, isSlowConnection } = useNetworkStatus();

    const currentYear = new Date().getFullYear();

    useEffect(() => {
        const fetchData = async (userRoleId, warehouseId) => {
            if (!userRoleId) {
                console.error("UserRoleId is required to fetch stats");
                return;
            }

            try {
                // Fetch the data from the API
                const data = await client.getManagementDashboard(
                    userRoleId,
                    warehouseId
                );

                if (!data?.results) {
                    throw new Error("Missing results in API response");
                }

                // Filter the data by 'metric_name'
                const filteredData = data.results.filter(
                    (item) => item.metric_name === "promotions"
                );

                // Sort the data by `total_revenue` (CA total) in descending order
                const sortedData = filteredData[0].json_data.sort((a, b) => {
                    return b.total_revenue - a.total_revenue; // descending order
                });

                setData(sortedData); // Set the sorted data
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        if (isOnline && !isSlowConnection) {
            client
                .fetchUser()
                .then((data) => {
                    if (
                        data &&
                        data.user_role_id &&
                        data.warehouse_id !== undefined
                    ) {
                        fetchData(data.user_role_id, data.warehouse_id);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching user details:", error);
                });
        }
    }, [isOnline, isSlowConnection]);

    const headers = [
        { label: "Nom de la promotion", field: "promo_name" },
        { label: "ID de la promotion", field: "promo_id" },
        { label: "Date de début", field: "start_date" },
        { label: "Date de fin", field: "end_date" },
        { label: "Remise (%)", field: "discount" },
        { label: "Type de promotion", field: "promo_type" },
        { label: "Unité", field: "unit" },
        { label: "CA total (k€)", field: "total_revenue" },
        { label: "Volume total (hL)", field: "total_volume" },
    ];

    useEffect(() => {
        console.log("data", data);
    }, [data]);

    return (
        <div className="w-full overflow-x-auto">
            <table
                className="w-full border-collapse text-xs table-auto"
                style={{ tableLayout: "fixed" }}
            >
                <thead>
                    <tr>
                        {headers.map((header) => (
                            <th
                                key={header.field}
                                className="border-b border-gray-300 px-4 py-2 text-left"
                                style={{ minWidth: "150px" }}
                            >
                                {header.label}
                            </th>
                        ))}
                    </tr>
                </thead>
            </table>

            {/* Scrollable Body */}
            <div
                className="max-h-64 overflow-y-auto w-full"
                style={{ height: "256px" }} // Fixed height
            >
                <table
                    className="w-full border-collapse text-xs table-auto"
                    style={{ tableLayout: "fixed" }}
                >
                    <tbody>
                        {data.map((promo) => (
                            <tr key={promo.promo_id}>
                                {headers.map((header) => (
                                    <td
                                        key={header.field}
                                        className="border-b border-dashed border-gray-300 px-4 py-2 text-left w-full"
                                    >
                                        {promo[header.field] || "-"}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const Dashboard = () => {
    const { isOnline, isSlowConnection } = useNetworkStatus();

    const [locale, setLocale] = useState();

    const [chartData, setChartData] = useState([]);
    const [monthly_revenue_chart, setMonthlyRevenueChart] = useState([]);
    const [monthly_volume_chart, setMonthlyVolumeChart] = useState([]);
    const [doughnut_chart, setDoughnutChart] = useState([]);
    const [product_doughnut_chart, setProductDoughnutChart] = useState([]);
    const [annual_comparison, setAnnualComparison] = useState([]);
    const [tenant, setTenant] = useState([]);

    useEffect(() => {
        // Get the layout element from local storage
        const layout = localStorage.getItem("layoutData");

        if (layout) {
            try {
                // Parse the layout element to JSON
                const parsedLayout = JSON.parse(layout);

                // Set the tenant state to the tenant property of the layout object
                if (parsedLayout.tenant) {
                    setTenant(parsedLayout.tenant);
                }
            } catch (error) {
                console.error("Error parsing layout from localStorage:", error);
            }
        }
    }, []); // Run only once on component mount
    // const []

    useEffect(() => {
        // setRefreshing(false);
        console.log("monthly_revenue_chart", monthly_revenue_chart);
    }, [monthly_revenue_chart]);

    useEffect(() => {
        if (isOnline && !isSlowConnection) {
            setLocale(localStorage.getItem("language"));
            getUserDetails();
        }
    }, [isOnline, isSlowConnection]);

    const getUserDetails = () => {
        client
            .fetchUser()
            .then((data) => {
                // setUsername(data);

                // Ensure both user_role_id and warehouse_id exist before fetching data
                if (data && data.user_role_id) {
                    fetchChartData(data.user_role_id, data.warehouse_id);
                }
            })
            .catch((error) => {
                console.error("Error fetching user details:", error);
            });
    };

    const fetchChartData = (userRoleId, warehouseId) => {
        if (!userRoleId) {
            console.error(
                "Both userRoleId and warehouseId are required to fetch stats"
            );
            return;
        }

        client
            .getManagementDashboard(userRoleId, warehouseId)
            .then((data) => {
                setMonthlyRevenueChart(
                    data.results.filter(
                        (item) => item.metric_name === "monthly_revenue_summary"
                    )[0].json_data
                );
                setMonthlyVolumeChart(
                    data.results.filter(
                        (item) => item.metric_name === "monthly_volume_summary"
                    )[0].json_data
                );
                console.log("result", data);
                setDoughnutChart(
                    data.results.filter(
                        (item) => item.metric_name === "doughnut_data"
                    )[0].json_data
                );
                setProductDoughnutChart(
                    data.results.filter(
                        (item) => item.metric_name === "product_doughnut_data"
                    )[0].json_data
                );
                setAnnualComparison(
                    data.results.filter(
                        (item) => item.metric_name === "annual_comparison"
                    )
                );

                setChartData(data.results);
            })
            .catch((error) => {
                console.error(
                    "Error fetching management dashboard stats:",
                    error
                );
            });
    };

    // Function to generate gradient colors between white and black
    // Function to generate a gradient between two colors
    function generateGradientColors(startColor, endColor, numColors) {
        const start = {
            r: parseInt(startColor.slice(1, 3), 16),
            g: parseInt(startColor.slice(3, 5), 16),
            b: parseInt(startColor.slice(5, 7), 16),
        };
        const end = {
            r: parseInt(endColor.slice(1, 3), 16),
            g: parseInt(endColor.slice(3, 5), 16),
            b: parseInt(endColor.slice(5, 7), 16),
        };

        const colors = [];
        for (let i = 0; i < numColors; i++) {
            const ratio = i / (numColors - 1); // Calculate ratio between 0 and 1
            const r = Math.round(start.r + ratio * (end.r - start.r));
            const g = Math.round(start.g + ratio * (end.g - start.g));
            const b = Math.round(start.b + ratio * (end.b - start.b));
            colors.push(`rgb(${r}, ${g}, ${b})`);
        }
        return colors;
    }

    // Update doughnut data dynamically based on labels length
    const doughnut_data = {
        labels: ["Restaurant", "Café", "Bar", "Discotheque"], // Add labels as needed
        datasets: [
            {
                data: [300, 50, 100, 200], // Corresponding data values
                backgroundColor: generateGradientColors(
                    "#2D3F04",
                    "#C3FF36",
                    ["Restaurant", "Café", "Bar", "Discotheque"].length
                ), // Generate colors based on label count
            },
        ],
    };

    const doughnut_options = {
        plugins: {
            legend: {
                display: true,
                position: "right",
                text: "Client type",
                labels: {
                    font: {
                        size: 8, // Adjust the font size as needed
                    },
                },
            },
        },
        elements: {
            arc: {
                borderWidth: 0,
            },
        },
        cutout: "70%", // Adjust this percentage to make the arcs thinner or thicker
    };

    const stacked_options = {
        plugins: {
            legend: {
                position: "right",
                display: false,
            },
            title: {
                display: false,
                text: "Chart.js Bar Chart - Stacked",
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: "black",
                },
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                ticks: {
                    color: "black",
                },
                grid: {
                    display: true, // Show horizontal grid lines
                    color: "rgba(0, 0, 0, 0.1)", // Light gray color for subtle lines
                    lineWidth: 1, // Customize line thickness
                    borderDash: [5, 5], // Optional: dashed lines for a lighter look
                },
            },
        },
        maintainAspectRatio: false, // Allow control over width and height
    };
    const nba_result_data = {
        labels: ["En attente", "Acceptée", "Refusé"],
        datasets: [
            {
                label: "beer",
                data: [450, 700, 250], // Example data
                backgroundColor: ["#000000", "#C3FF36", "#FF365E"], // Colors for each label
                borderRadius: 30,
                barThickness: 20, // Set to a smaller value for thinner bars
            },
        ],
    };

    const nba_type_data = {
        labels: [
            "Prévention départ des clients",
            "Recommandation produit",
            "Gestion des relations",
            "Optimisation du cycle de commande",
        ],
        datasets: [
            {
                label: "beer",
                data: [700, 450, 250, 200], // Example data
                backgroundColor: ["#000000", "#000000", "#000000", "#000000"], // Colors for each label
                borderRadius: 30,
                barThickness: 20, // Set to a smaller value for thinner bars
            },
        ],
    };
    return (
        <div className="flex flex-col ml-5 mr-5 md:mr-0 md:ml-0 md:flex-row pb-6 mb-20 text-black rounded-3xl">
            <div className=" container flex flex-col bg-white rounded-3xl md:ml-10 mr-10 w-full">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-2 align-start md:ml-10">
                    {annual_comparison &&
                        annual_comparison.map((stat, index) => (
                            <SmallStatisticsBox
                                key={index} // Or use a unique identifier if available
                                name={stat.json_data.name}
                                value_current_year={
                                    stat.json_data.value_current_year
                                }
                                change={stat.json_data.change}
                                value_previous_year={
                                    stat.json_data.value_previous_year
                                }
                                period={"annual"}
                            />
                        ))}
                    <SmallStatisticsBox
                        key={"A"} // Or use a unique identifier if available
                        name={"Taux de couverture"}
                        value_current_year={"0%"}
                        change={"0%"}
                        value_previous_year={"0%"}
                        period={"annual"}
                    />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-2 align-start md:ml-10">
                    <div className="p-4 bg-white rounded-md border border-gray-200 w-full text-left">
                        <h3 className="text-black text-lg ">
                            <FormattedMessage
                                id="performance.sales_by_product_type"
                                values={{ number: 1 }}
                            />
                        </h3>
                        <div
                            style={{
                                minHeight: 250,
                                maxWidth: 650,
                            }}
                            className="mb-5 mt-5"
                        >
                            {monthly_revenue_chart.labels && (
                                <Bar
                                    options={stacked_options}
                                    data={monthly_revenue_chart}
                                />
                            )}
                        </div>
                    </div>
                    <div className="p-4 bg-white rounded-md border border-gray-200 w-full text-left">
                        <h3 className="text-black text-lg">Volume (hl)</h3>
                        <div
                            style={{
                                minHeight: 250,
                                maxWidth: 650,
                            }}
                            className="mb-5 mt-5"
                        >
                            {monthly_revenue_chart.labels && (
                                <Bar
                                    options={stacked_options}
                                    data={monthly_volume_chart}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-2 align-start md:ml-10 mt-4">
                    <div className="p-4 bg-white rounded-md border border-gray-200 w-full text-left">
                        <h3 className="text-black text-lg">
                            Répartition des clients (€)
                        </h3>
                        <div
                            className="flex justify-center items-center"
                            style={{
                                maxWidth: 300,
                                margin: "0 auto",
                            }}
                        >
                            {doughnut_chart.labels && (
                                <Doughnut
                                    options={doughnut_options}
                                    data={doughnut_chart}
                                />
                            )}
                        </div>
                    </div>
                    <div className="p-4 bg-white rounded-md border border-gray-200 w-full text-left">
                        <h3 className="text-black text-lg">
                            Répartition par catégorie produits (€)
                        </h3>
                        <div
                            className="flex justify-center items-center"
                            style={{
                                maxWidth: 300,
                                margin: "0 auto",
                            }}
                        >
                            {doughnut_chart.labels && (
                                <Doughnut
                                    options={doughnut_options}
                                    data={product_doughnut_chart}
                                />
                            )}
                        </div>
                    </div>
                </div>

                <div className="w-full flex justify-center mt-4 ml-10 pr-10">
                    <div className="p-4 bg-white flex flex-col justify-center rounded-md border border-gray-200 w-full">
                        <div className="text-left">
                            <h3 className="text-black text-lg">
                                Comparaison par groupe client (€)
                            </h3>
                        </div>

                        <TableWithClientMetrics />
                        {/* <div className="w-full flex flex-col justify-center mt-4 pr-10">
                            <h3 className="pb-1 text-black text-lg text-left w-full flex flex-row space-x-3 justify-start items-center">
                                <FiCheckSquare />
                                <span>Next best actions</span>
                            </h3>
                            <div className="bg-white flex flex-row justify-center  w-full space-x-2 text-left py-5">
                                <div className="w-1/2 flex rounded-md border border-gray-200 items-center py-3">
                                    <div className="flex h-full w-20 text-xxl items-center justify-center text-gray-500">
                                        <FiInfo />
                                    </div>
                                    <div>
                                        Marge dans les restaurants en baisse
                                        d&apos;1 point.
                                        <br />
                                        Accélérer les ventes d&apos;articles
                                        avec une marge plus élevée.
                                    </div>
                                </div>
                                <div className="p-2 w-1/2 flex rounded-md border border-gray-200 items-center py-5">
                                    <div className="flex h-full w-20 text-xxl items-center justify-center text-gray-500">
                                        <FiInfo />
                                    </div>
                                    <div className="h-full">
                                        Nombre de clients à risque en hausse
                                        dans les brasseries.
                                        <br />
                                        Mener des enquêtes de satisfaction.
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className="w-full flex justify-center mt-4 ml-10 pr-10">
                    <div className="p-4 bg-white flex flex-col justify-center rounded-md border border-gray-200 w-full">
                        <div className="text-left">
                            <h3 className="text-black text-lg">
                                Comparaison par famille de produit
                            </h3>
                        </div>
                        <TableWithProductMetrics />
                        {/* <div className="w-full flex flex-col justify-center mt-4 pr-10">
                            <h3 className="pb-1 text-black text-lg text-left w-full flex flex-row space-x-3 justify-start items-center">
                                <FiCheckSquare />
                                <span>Next best actions</span>
                            </h3>
                            <div className="bg-white flex flex-row justify-center  w-full space-x-2 text-left py-5">
                                <div className="w-1/2 flex rounded-md border border-gray-200 items-center py-3">
                                    <div className="flex h-full w-20 text-xxl items-center justify-center text-gray-500">
                                        <FiInfo />
                                    </div>
                                    <div>
                                        Les ventes de Corona diminuent en raison
                                        de promotions en cours de la part de
                                        concurrents.
                                        <br />
                                        Proposer de lancer une offre
                                        promotionnelle pour accélérer les
                                        ventes.
                                    </div>
                                </div>
                                <div className="p-2 w-1/2 flex rounded-md border border-gray-200 items-center py-5">
                                    <div className="flex h-full w-20 text-xxl items-center justify-center text-gray-500">
                                        <FiInfo />
                                    </div>
                                    <div className="h-full">
                                        Les ventes des boissons sans alcool
                                        n&apos;augmentent pas
                                        proportionnellement à celles des
                                        spiritueux.
                                        <br />
                                        Inciter les commerciaux à associer les
                                        deux catégories produits lors d&apos;une
                                        vente.
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className="w-full flex justify-center mt-4 ml-10 pr-10">
                    <div className="p-4 bg-white flex flex-col justify-center rounded-md border border-gray-200 w-full">
                        <div className="text-left">
                            <h3 className="text-black text-lg">
                                Comparaison par commercial
                            </h3>
                        </div>

                        <TableWithSalesRepMetrics />
                        {/* <div className="w-full flex flex-col justify-center mt-4 pr-10">
                            <h3 className="pb-1 text-black text-lg text-left w-full flex flex-row space-x-3 justify-start items-center">
                                <FiCheckSquare />
                                <span>Next best actions</span>
                            </h3>
                            <div className="bg-white flex flex-row justify-center  w-full space-x-2 text-left py-5">
                                <div className="w-1/2 flex rounded-md border border-gray-200 items-center py-3">
                                    <div className="flex h-full w-20 text-xxl items-center justify-center text-gray-500">
                                        <FiInfo />
                                    </div>
                                    <div>
                                        Taux de départ client en hausse et taux
                                        de conversion en baisse pour Thomas.
                                        <br />
                                        Programmer une formation.
                                    </div>
                                </div>
                                <div className="p-2 w-1/2 flex rounded-md border border-gray-200 items-center py-5">
                                    <div className="flex h-full w-20 text-xxl items-center justify-center text-gray-500">
                                        <FiInfo />
                                    </div>
                                    <div className="h-full">
                                        Marie vient d&apos;établir un nouveau
                                        record en convertissant le plus grand
                                        nombre de prospects en un mois !
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>

                {tenant != "client_d4be15" && (
                    <div className="w-full flex justify-center mt-4 ml-10 pr-10">
                        <div className="p-4 bg-white flex flex-col justify-center rounded-md border border-gray-200 w-full">
                            <div className="text-left">
                                <h3 className="text-black text-lg">
                                    Promotions {tenant}
                                </h3>
                            </div>

                            <TableWithPromotionMetrics />
                            {/* <div className="w-full flex flex-col justify-center mt-4 pr-10">
                            <h3 className="pb-1 text-black text-lg text-left w-full flex flex-row space-x-3 justify-start items-center">
                                <FiCheckSquare />
                                <span>Next best actions</span>
                            </h3>
                            <div className="bg-white flex flex-row justify-center  w-full space-x-2 text-left py-5">
                                <div className="w-1/2 flex rounded-md border border-gray-200 items-center py-3">
                                    <div className="flex h-full w-20 text-xxl items-center justify-center text-gray-500">
                                        <FiInfo />
                                    </div>
                                    <div>
                                        Taux de départ client en hausse et taux
                                        de conversion en baisse pour Thomas.
                                        <br />
                                        Programmer une formation.
                                    </div>
                                </div>
                                <div className="p-2 w-1/2 flex rounded-md border border-gray-200 items-center py-5">
                                    <div className="flex h-full w-20 text-xxl items-center justify-center text-gray-500">
                                        <FiInfo />
                                    </div>
                                    <div className="h-full">
                                        Marie vient d&apos;établir un nouveau
                                        record en convertissant le plus grand
                                        nombre de prospects en un mois !
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        </div>
                    </div>
                )}

                {/* <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-2 align-start md:ml-10 mt-4">
                    <div className="p-4 bg-white rounded-md border border-gray-200 w-full text-left">
                        <h3 className="text-black text-lg ">
                            Aperçu des next best actions exécutées
                        </h3>
                        <div
                            className="mb-5 mt-5"
                            style={{
                                minHeight: 250,
                                maxWidth: 650,
                            }}
                        >
                            <Bar
                                options={stacked_options}
                                data={nba_result_data}
                            />
                        </div>
                    </div>
                    <div className="p-4 bg-white rounded-md border border-gray-200 w-full text-left">
                        <h3 className="text-black text-lg">
                            Types de next best actions
                        </h3>
                        <div
                            className="mb-5 mt-5"
                            style={{
                                minHeight: 250,
                                maxWidth: 650,
                            }}
                        >
                            <Bar
                                options={stacked_options}
                                data={nba_type_data}
                            />
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default Dashboard;

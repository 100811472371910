import React from "react";

import { FiPercent } from "react-icons/fi";
import { FiExternalLink } from "react-icons/fi";

const Product = ({ product }) => {
    return (
        product && (
            <>
                <div className="flex items-center mt-2 text-black">
                    <div className="flex flex-row items-center p-2 mb-1 bg-white">
                        <div className="float-left pl-2 w-1/6 relative">
                            <img src={product?.image}></img>
                            {product?.promotion?.is_active && (
                                <div className="absolute right-0 top-0 translate-x-1/2 -translate-y-1/2 bg-rose-500 w-6 h-6 rounded-full text-white px-1 py-1 text-xs flex justify-center items-center">
                                    <FiPercent />
                                </div>
                            )}
                        </div>

                        <div className="float-left ml-7 text-sm w-3/4 text-left">
                            <div className="flex flex-row items-center">
                                <p className="text-sm md:text-xs xl:text-sm">
                                    <b>{product?.short_label}</b>{" "}
                                </p>
                                <button
                                    className="ml-10"
                                    onClick={() =>
                                        window.open(product?.shop_url, "_blank")
                                    }
                                >
                                    <FiExternalLink />
                                </button>
                            </div>

                            {/* <p className="mt-2 md:mt-1 xl:md-2">
                                {product?.product_desc &&
                                product.product_desc.length > 50
                                    ? `${product.product_desc.substring(
                                          0,
                                          50
                                      )}...`
                                    : product.product_desc}
                            </p> */}
                        </div>
                    </div>
                </div>
            </>
        )
    );
};

export default Product;

import React, { useEffect, useState, useMemo } from "react";
import FastAPIClient from "../../client";
import config from "../../config";
import ClientNew from "../../components/LayoutComponents/ClientNew";
import Loader from "../../components/Loader";
import { useOfflineMeetings } from "../../components/OfflineContext";
import "./style.css";
import { FormattedMessage, IntlProvider } from "react-intl";
import messages_en from "./translations/en.json";
import messages_fr from "./translations/fr.json";
import ClientSearch from "../../components/Search/ClientSearch";

const client = new FastAPIClient(config);

const messages = {
    en: messages_en,
    fr: messages_fr,
};

const ProfileView = ({ clients, language }) => {
    return (
        <>
            {clients.length &&
                clients.map((client) => (
                    <ClientNew
                        alert={client.alert}
                        type={client.type}
                        key={client.id}
                        client={client}
                        language={language}
                        last_interaction={client.last_meeting_start_date}
                    />
                ))}
            {!clients.length && (
                <p>
                    Pas de clients trouvés avec les critères de recherche
                    définis
                </p>
            )}
        </>
    );
};

const ClientDashboard = () => {
    const [clients, setClients] = useState([]);

    const [filterOptions, setFilterOptions] = useState({});
    const [locale, setLocale] = useState();
    const [refreshing, setRefreshing] = useState(true);
    const [sortOption, setSortOption] = useState(""); // Default sort option
    const [user, setUser] = useState([]);
    const { offlineRecommendations } = useOfflineMeetings();

    // useEffect(() => {
    //     fetchUserClients();
    // }, [user]);

    const getUser = () => {
        client.fetchUser().then((data) => {
            // Set the username in the state
            console.log(data);
            setUser(data);
        });
    };

    useEffect(() => {
        setLocale(localStorage.getItem("language"));
        getUser();
    }, []);

    useEffect(() => {
        if (clients.length > 0) {
            const options = {};
            clients.forEach((client) => {
                // Extracting unique values for each column you want to use as a filter
                // Example: Assuming 'type' and 'arrondissement' are columns in clients data
                if (client.client_level_1) {
                    options.Type = options.Type || [];
                    if (!options.Type.includes(client.client_level_1)) {
                        options.Type.push(client.client_level_1);
                    }
                }

                if (client.is_seasonal) {
                    options.is_seasonal = options.is_seasonal || [];
                    if (!options.is_seasonal.includes(client.is_seasonal)) {
                        options.is_seasonal.push(client.is_seasonal);
                    }
                }

                if (client.is_active) {
                    options.is_active = options.is_active || [];
                    if (!options.is_active.includes(client.is_active)) {
                        options.is_active.push(client.is_active);
                    }
                }
                if (client.churn_risk) {
                    options.churn_risk = options.churn_risk || [];
                    if (!options.churn_risk.includes(client.churn_risk)) {
                        options.churn_risk.push(client.churn_risk);
                    }
                }
                if (client.segment) {
                    options.segment = options.segment || [];
                    if (!options.segment.includes(client.segment)) {
                        options.segment.push(client.segment);
                    }
                }
                if (client.city) {
                    options.city = options.city || [];
                    if (!options.city.includes(client.city)) {
                        options.city.push(client.city);
                    }
                }
                if (client.is_new) {
                    options.is_new = options.is_new || [];
                    if (!options.is_new.includes(client.is_new)) {
                        options.is_new.push(client.is_new);
                    }
                }
                if (client.visit_days) {
                    options.visit_days = options.visit_days || [];
                    if (!options.visit_days.includes(client.visit_days)) {
                        options.visit_days.push(client.visit_days);
                    }
                }

                // Add more columns here...
            });
            setFilterOptions(options);
        }
    }, [clients]);

    // State to hold selected filters
    const [selectedFilters, setSelectedFilters] = useState({});

    const applyFilters = useMemo(() => {
        if (!clients.length) return [];
        let filteredClients = clients;

        if (Object.keys(selectedFilters).length > 0) {
            filteredClients = filteredClients.filter((client) => {
                return (
                    (!selectedFilters.Type?.length ||
                        selectedFilters.Type.includes(client.client_level_1)) &&
                    (!selectedFilters.is_seasonal?.length ||
                        selectedFilters.is_seasonal.includes(
                            client.is_seasonal
                        )) &&
                    (!selectedFilters.is_new?.length ||
                        selectedFilters.is_new.includes(client.is_new)) &&
                    (!selectedFilters.is_active?.length ||
                        selectedFilters.is_active.includes(client.is_active)) &&
                    (!selectedFilters.churn_risk?.length ||
                        selectedFilters.churn_risk.includes(
                            client.churn_risk
                        )) &&
                    (!selectedFilters.segment?.length ||
                        selectedFilters.segment.includes(client.segment)) &&
                    (!selectedFilters.city?.length ||
                        selectedFilters.city.includes(client.city)) &&
                    (!selectedFilters.visit_days?.length ||
                        selectedFilters.visit_days.includes(client.visit_days))
                );
            });
        }

        if (sortOption) {
            const [field, order] = sortOption.split("_");
            filteredClients = [...filteredClients].sort((a, b) => {
                if (field === "name") {
                    return (
                        (a.client_name || "").localeCompare(
                            b.client_name || ""
                        ) * (order === "asc" ? 1 : -1)
                    );
                } else if (field === "alert") {
                    return (a.alert - b.alert) * (order === "asc" ? 1 : -1);
                } else if (field === "last_meeting_start_date") {
                    return (
                        (a.last_meeting_start_date -
                            b.last_meeting_start_date) *
                        (order === "asc" ? 1 : -1)
                    );
                }
                return 0;
            });
        }

        if (offlineRecommendations.length) {
            return filteredClients.map((client) => {
                const matchingRecommendation = offlineRecommendations.find(
                    (recommendation) =>
                        recommendation.client_id === client.client_id
                );
                return matchingRecommendation
                    ? {
                          ...client,
                          alert: false,
                      }
                    : client;
            });
        }

        return filteredClients;
    }, [clients, selectedFilters, sortOption, offlineRecommendations]);

    const toggleSort = (field) => {
        if (!sortOption || !sortOption.startsWith(field)) {
            setSortOption(`${field}_asc`);
        } else if (sortOption === `${field}_asc`) {
            setSortOption(`${field}_desc`);
        } else {
            setSortOption(null);
        }
    };

    // Function to handle filter selection and deselection
    const handleFilterSelection = (filterType, option) => {
        const updatedFilters = { ...selectedFilters };

        // If the option is already selected, deselect it
        if (updatedFilters[filterType]?.includes(option)) {
            updatedFilters[filterType] = updatedFilters[filterType].filter(
                (item) => item !== option
            );
        } else {
            // If not selected, select the option
            updatedFilters[filterType] = updatedFilters[filterType]
                ? [...updatedFilters[filterType], option]
                : [option];
        }

        // If no options are selected for a filter, remove the filter
        if (updatedFilters[filterType]?.length === 0) {
            delete updatedFilters[filterType];
        }

        setSelectedFilters(updatedFilters);
    };

    // const fetchUserClients = () => {
    //     // Check if user_id is not null or undefined
    //     if (user.user_id != null) {
    //         console.log(user.user_id);
    //         client;
    //         client.getUserClients(user.user_id, null).then((data) => {
    //             console.log(data);
    //             const sortedResults = data.sort((a, b) => {
    //                 // Move objects with 'alert' as true to the beginning
    //                 return b.alert - a.alert; // b.alert - a.alert will move true values to the top
    //             });
    //             setClients(sortedResults);
    //             console.log(sortedResults);
    //         });
    //     } else {
    //         console.log("user_id is null or undefined, skipping API call.");
    //     }
    // };

    useEffect(() => {
        const handleOnlineStatus = () => {
            if (navigator.onLine) {
                fetchUserClientsOnline();
            } else {
                fetchUserClientsOffline();
            }
        };

        // Run once on mount
        handleOnlineStatus();

        // Listen for network status changes
        window.addEventListener("online", handleOnlineStatus);
        window.addEventListener("offline", handleOnlineStatus);

        return () => {
            window.removeEventListener("online", handleOnlineStatus);
            window.removeEventListener("offline", handleOnlineStatus);
        };
    }, [user]);

    const fetchUserClientsOffline = () => {
        if (user.user_id != null) {
            client.getUserClients(user.user_id, null).then((data) => {
                const sortedResults = data.sort((a, b) => {
                    // Move objects with 'alert' as true to the beginning
                    return b.alert - a.alert; // b.alert - a.alert will move true values to the top
                });
                setClients(sortedResults);
            });
        } else {
            console.log("user_id is null or undefined, skipping API call.");
        }
    };

    const fetchUserClientsOnline = () => {
        if (user.user_id != null) {
            client.getUserClientswInfo(user.user_id, null).then((data) => {
                const sortedResults = data.sort((a, b) => {
                    // Move objects with 'alert' as true to the beginning
                    return b.alert - a.alert; // b.alert - a.alert will move true values to the top
                });
                setClients(sortedResults);
            });
        } else {
            console.log("user_id is null or undefined, skipping API call.");
        }
    };

    useEffect(() => {
        if (clients.length > 0) {
            setRefreshing(false);
        }
    }, [clients]);
    console.log(clients);

    // Function to remove all filters
    const removeAllFilters = () => {
        setSelectedFilters({}); // Reset all filters
    };

    const [expandedFilters, setExpandedFilters] = React.useState({});

    const toggleExpanded = (filterType) => {
        setExpandedFilters((prev) => ({
            ...prev,
            [filterType]: !prev[filterType],
        }));
    };

    const filterStyle = `cursor-pointer hover:bg-gray-200 hover:text-buttonSecondaryHoverFontColor text-buttonSecondaryFontColor px-4 py-2 mx-auto rounded-full mt-2 md:mt-0 text-xs`;

    if (refreshing) {
        // Redirect to another page if user is not logged in
        return <Loader />;
    }

    const filterLabels = {
        is_active: "Active",
        churn_risk: "Risque de départ",
        segment: "Segement CA",
        city: "Ville",
        is_seasonal: "Saisonnier",
        visit_days: "Jours de visite",
    };

    const daysOfWeek = [
        "Lundi", // Monday
        "Mardi", // Tuesday
        "Mercredi", // Wednesday
        "Jeudi", // Thursday
        "Vendredi", // Friday
        "Samedi", // Saturday
        "Dimanche", // Sunday
    ];

    return (
        <>
            <div className="flex flex-row">
                <IntlProvider locale={locale} messages={messages[locale]}>
                    <div className="container px-15 pt-10 md:pt-20 bg-white mx-auto lg:px-10">
                        <div className="flex flex-col md:flex-row md:items-center md:mr-20 mr-10">
                            <div className="flex w-1/2 xl:w-1/4">
                                <h1 className="ml-5 md:ml-0 md:mb-12 text-xl text-black md:text-left">
                                    <FormattedMessage id="clients.client_overview" />
                                </h1>{" "}
                            </div>
                            <div className="flex flex-col md:flex-row ml-5 mr-5 md:mr-20 md:ml-20 xl:ml-0 mt-5 items-center justify-center mb-5 md:mb-20 w-full">
                                <ClientSearch clients={applyFilters} />
                            </div>
                        </div>

                        <div className="filter ml-5 flex flex-col hidden">
                            <div className="filter">
                                <div className="flex flex-col float-left text-left">
                                    {Object.keys(filterOptions).map(
                                        (filterType) => (
                                            <div key={filterType}>
                                                <span className="font-semi-bold mt-5 pb-5 text-sm">
                                                    {filterLabels[filterType] ||
                                                        filterType}{" "}
                                                    hello
                                                </span>
                                                {filterOptions[filterType].map(
                                                    (option) => (
                                                        <label
                                                            className="custom_check mt-5"
                                                            key={option}
                                                        >
                                                            {option} hello
                                                            <input
                                                                type="checkbox"
                                                                checked={
                                                                    selectedFilters[
                                                                        filterType
                                                                    ] &&
                                                                    selectedFilters[
                                                                        filterType
                                                                    ].includes(
                                                                        option
                                                                    )
                                                                }
                                                                onChange={() =>
                                                                    handleFilterSelection(
                                                                        filterType,
                                                                        option
                                                                    )
                                                                }
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    )
                                                )}
                                                <hr className="my-8 h-px"></hr>
                                            </div>
                                        )
                                    )}
                                    <label
                                        className="text-xs font-bold"
                                        onClick={removeAllFilters}
                                    >
                                        x Remove all filters
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="w-full xl:w-4/5 md:mt-10 items-center pl-5 pr-5 mr-5 md:pl-0 md:ml-0 text-left">
                            <div className="flex flex-row">
                                <div className={`md:w-1/6 sm:w-1/3`}>
                                    Trier par:
                                </div>
                                <button
                                    className={`${filterStyle} ${
                                        sortOption?.startsWith("name") ? "" : ""
                                    } md:w-1/6 sm:w-1/3`}
                                    onClick={() => toggleSort("name")}
                                >
                                    Nom du client{" "}
                                    {sortOption === "name_asc"
                                        ? "↑"
                                        : sortOption === "name_desc"
                                        ? "↓"
                                        : ""}
                                </button>
                                <button
                                    className={`${filterStyle} ${
                                        sortOption?.startsWith("alert")
                                            ? "-gray-200"
                                            : ""
                                    } md:w-1/6 sm:w-1/3`}
                                    onClick={() => toggleSort("alert")}
                                >
                                    Recommendation{" "}
                                    {sortOption === "alert_asc"
                                        ? "↑"
                                        : sortOption === "alert_desc"
                                        ? "↓"
                                        : ""}
                                </button>
                                <button
                                    className={`${filterStyle} ${
                                        sortOption?.startsWith(
                                            "last_meeting_start_date"
                                        )
                                            ? "-gray-200"
                                            : ""
                                    } md:w-1/6 sm:w-1/3`}
                                    onClick={() =>
                                        toggleSort("last_meeting_start_date")
                                    }
                                >
                                    Derniere interaction{" "}
                                    {sortOption ===
                                    "last_meeting_start_date_asc"
                                        ? "↑"
                                        : sortOption ===
                                          "last_meeting_start_date_desc"
                                        ? "↓"
                                        : ""}
                                </button>
                            </div>
                        </div>

                        <div className="text-white w-full xl:w-4/5 mt-10 items-center pl-5 pr-5 mr-5 md:pl-0 md:ml-0 text-left">
                            <>
                                {applyFilters.length > 0 ? (
                                    <ProfileView
                                        clients={applyFilters}
                                        client={client}
                                        language={locale}
                                    />
                                ) : (
                                    <p>No matching clients found!</p>
                                )}
                            </>
                        </div>

                        <div className="flex flex-col items-center md:hidden">
                            <div className="flex flex-row border-client border-2 rounded-full items-center w-1/3 p-2">
                                <div className=" flex flex-row ml-7">
                                    <img
                                        src="https://www.svgrepo.com/show/532169/filter.svg"
                                        height="15"
                                        width="15"
                                    />
                                    <h1>Filter</h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    {clients.length > 0 ? (
                        <>
                            <div className="filter mt-20 xl-1/3 hidden md:flex xl:w-1/5">
                                <div className="filter mt-20">
                                    <div className="flex flex-col float-left text-left">
                                        <span className="font-bold mt-20 mb-5">
                                            {applyFilters.length}{" "}
                                            <FormattedMessage id="clients.clients" />{" "}
                                        </span>
                                        {Object.keys(filterOptions).map(
                                            (filterType) => {
                                                const isExpanded =
                                                    expandedFilters[
                                                        filterType
                                                    ] || false;
                                                const options =
                                                    filterOptions[filterType];
                                                const visibleOptions =
                                                    isExpanded
                                                        ? options
                                                        : options.slice(0, 2);

                                                return (
                                                    <div
                                                        key={filterType}
                                                        className="mb-5"
                                                    >
                                                        <div className="font-semi-bold mt-2 pb-1 text-sm">
                                                            {filterLabels[
                                                                filterType
                                                            ] || filterType}
                                                        </div>
                                                        {/* TODO: make this filter right */}
                                                        {visibleOptions
                                                            .sort((a, b) => {
                                                                // Sort options alphabetically
                                                                const optionStrA =
                                                                    String(
                                                                        a
                                                                    ).toLowerCase();
                                                                const optionStrB =
                                                                    String(
                                                                        b
                                                                    ).toLowerCase();
                                                                return optionStrA.localeCompare(
                                                                    optionStrB
                                                                );
                                                            })
                                                            .filter(
                                                                (option) => {
                                                                    const optionStr =
                                                                        String(
                                                                            option
                                                                        );
                                                                    // Exclude options that match the "multi" pattern
                                                                    return (
                                                                        !/^\[\d,( \d)*\]$/.test(
                                                                            optionStr
                                                                        ) &&
                                                                        optionStr !==
                                                                            "[]" &&
                                                                        optionStr !==
                                                                            "[0, 1, 2, 3, 4]" &&
                                                                        !/^\[\d,( \d),( \d)*\]$/.test(
                                                                            optionStr
                                                                        )
                                                                    );
                                                                }
                                                            )
                                                            .map((option) => (
                                                                <label
                                                                    className="custom_check mt-5"
                                                                    key={option}
                                                                >
                                                                    {(() => {
                                                                        const optionStr =
                                                                            String(
                                                                                option
                                                                            ); // Convert option to a string
                                                                        if (
                                                                            optionStr ===
                                                                            "true"
                                                                        ) {
                                                                            return "Oui";
                                                                        } else if (
                                                                            optionStr ===
                                                                            "false"
                                                                        ) {
                                                                            return "Non";
                                                                        } else if (
                                                                            optionStr ===
                                                                            "Low"
                                                                        ) {
                                                                            return "Faible";
                                                                        } else if (
                                                                            optionStr ===
                                                                            "Medium"
                                                                        ) {
                                                                            return "Moyen";
                                                                        } else if (
                                                                            optionStr ===
                                                                            "High"
                                                                        ) {
                                                                            return "Élévé";
                                                                        } else if (
                                                                            /^\[\d\]$/.test(
                                                                                optionStr
                                                                            )
                                                                        ) {
                                                                            // Handle cases like "[0]" or "[1]"
                                                                            const dayIndex =
                                                                                parseInt(
                                                                                    optionStr.slice(
                                                                                        1,
                                                                                        -1
                                                                                    ),
                                                                                    10
                                                                                ); // Extract the index from "[0]"
                                                                            return (
                                                                                daysOfWeek[
                                                                                    dayIndex
                                                                                ] ||
                                                                                optionStr
                                                                            ); // Return the corresponding day
                                                                        } else if (
                                                                            /^\[\d(, \d)*\]$/.test(
                                                                                optionStr
                                                                            )
                                                                        ) {
                                                                            // Handle strings like "[0,1,2,3]"
                                                                            return optionStr; // If multiple days, return "multi"
                                                                        } else if (
                                                                            optionStr ===
                                                                            "[0,1,2,3,4]"
                                                                        ) {
                                                                            // Specific case for "[0,1,2,3,4]"
                                                                            return "undefined";
                                                                        } else {
                                                                            return optionStr; // Fallback for other values
                                                                        }
                                                                    })()}{" "}
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={
                                                                            selectedFilters[
                                                                                filterType
                                                                            ] &&
                                                                            selectedFilters[
                                                                                filterType
                                                                            ].includes(
                                                                                option
                                                                            )
                                                                        }
                                                                        onChange={() =>
                                                                            handleFilterSelection(
                                                                                filterType,
                                                                                option
                                                                            )
                                                                        }
                                                                    />
                                                                    <span className="checkmark"></span>
                                                                </label>
                                                            ))}
                                                        {options.length > 2 && (
                                                            <button
                                                                className="font-bold text-xs mt-3"
                                                                onClick={() =>
                                                                    toggleExpanded(
                                                                        filterType
                                                                    )
                                                                }
                                                            >
                                                                {isExpanded
                                                                    ? "Afficher moins"
                                                                    : `+${
                                                                          options.length -
                                                                          2
                                                                      } de plus`}
                                                            </button>
                                                        )}
                                                        <hr className=""></hr>
                                                    </div>
                                                );
                                            }
                                        )}
                                        <label
                                            className="text-xs font-bold"
                                            onClick={removeAllFilters}
                                        >
                                            <FormattedMessage id="clients.remove_filter" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="filter mt-20 xl-1/3 hidden md:flex xl:w-1/5"></div>
                        </>
                    )}
                </IntlProvider>
            </div>
        </>
    );
};

export default ClientDashboard;

// React and routing
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

// Styles
import "react-datepicker/dist/react-datepicker.css";
import "./list.css";
import "@sendbird/uikit-react/dist/index.css";

// Utilities and configurations
import FastAPIClient from "../../client";
import config from "../../config";

// Internationalization
import { FormattedMessage, IntlProvider } from "react-intl";
import messages_en from "./translations/en.json";
import messages_fr from "./translations/fr.json";

// Components
import Product from "../../components/LayoutComponents/Product";
import List from "../../components/ListComponents/Listelement/List";
import CustomerDetails from "../../components/LayoutComponents/CustomerDetails/CustomerDetails";
import Loader from "../../components/Loader";
import CollapseComponent from "../../components/CollapseComponents/CollapseComponent";
import BackButton from "../../components/Buttons/BackButton";
import OrderHistory from "../../components/LayoutComponents/OrderHistory";
import VisitHistory from "../../components/LayoutComponents/VisitHistory";
import ClientOrders from "../../components/LayoutComponents/ClientOrders";
import ReportForm from "../../components/LayoutComponents/ReportForm";
import ClientStatistics from "../../components/LayoutComponents/ClientStatistics";
import CreateMeetingModal from "../../components/ModalComponents/CreateMeetingModal";
import Channel from "../../components/Chat/Channel";
import { useOfflineMeetings } from "../../components/OfflineContext";

// Icons
import {
	FiBarChart2,
	FiBookOpen,
	FiEdit,
	FiShoppingBag,
	FiShoppingCart,
	FiShuffle,
	FiUsers,
	FiFileText,
	// FiAlertTriangle,
	FiCalendar,
	FiMessageSquare,
} from "react-icons/fi";

import {
	CACHE_RESPONSE_API,
	CACHE_RESPONSE_DATA,
} from "../../sw/utils/constants";
import { getDataFromStore } from "../../sw/utils/db";
import { useNetwork } from "../../components/NetworkProvider";

// Initialize client and messages
const client = new FastAPIClient(config);

const messages = {
	en: messages_en,
	fr: messages_fr,
};

const formatModalDate = (date) => {
	const d = new Date(date);
	const year = d.getFullYear();
	const month = String(d.getMonth() + 1).padStart(2, "0");
	const day = String(d.getDate()).padStart(2, "0");
	const hours = String(d.getHours()).padStart(2, "0");
	const minutes = String(d.getMinutes()).padStart(2, "0");
	return `${year}-${month}-${day}T${hours}:${minutes}`;
};

const useMediaQuery = (query) => {
	const [matches, setMatches] = useState(false);

	useEffect(() => {
		const media = window.matchMedia(query);
		setMatches(media.matches);

		const handler = (event) => setMatches(event.matches);
		media.addListener(handler);

		return () => media.removeListener(handler);
	}, [query]);

	return matches;
};

const ClientPage = () => {
	const { clientId } = useParams();
	const [clientInfo, setClientInfo] = useState([]);
	const [product, setProductRecos] = useState([]);
	const [list, setList] = useState([]);
	const [meetinghistory, setClientMeetingHistory] = useState([]);
	const [clientorders, setClientOrders] = useState([]);
	const [clientreceipts, setClientReceipts] = useState([]);
	const [reportlayout, setReportLayout] = useState([]);
	const [clientstats, setClientStats] = useState([]);
	const targetDivRef = useRef(null);
	const [refreshing, setRefreshing] = useState(true);
	const [openmodal, setOpenModal] = useState(false);
	const [locale, setLocale] = useState();
	const [user, setUser] = useState(false);
	const [shopURL, setUrl] = useState([]);
	const [toggle, setToggle] = useState(false);
	const [message, setMessage] = useState(null); // State to store message
	const [chatMessages, setChatMessage] = useState([]);
	const [messageType, setMessageType] = useState(null); // Type of message (success or error)
	const { isOnline } = useNetwork();

	console.log("reportlayout", reportlayout);
	console.log(shopURL);

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const meetingId = queryParams.get("meeting_id"); // Extract meeting_id
	const meeting_type_id = queryParams.get("meeting_type_id"); // Extract meeting_id
	const {
		offlineMeetings,
		offlineRecommendations,
		offlineReports,
		offlineUpdateMeeting,
	} = useOfflineMeetings();

	const isMdOrLarger = useMediaQuery("(min-width: 768px)");

	const now = new Date();
	const initialStartDate = formatModalDate(now); // Current date and time
	const initialEndDate = formatModalDate(new Date(now.getTime() + 30 * 60000)); // Current date and time plus 30 minutes

	const [newEventData, setNewEventData] = useState({
		title: clientInfo.clientName,
		start: initialStartDate,
		end: initialEndDate,
		attendees: [],
	});

	const formatReports = (data) => {
		if (!data?.length) return [];
		return data.map((item) => {
			return {
				user: {
					user_id: user?.user_id,
					first_name: user?.first_name,
					last_name: user?.last_name,
				},
				type: {
					created_at: null,
					description: "Visite",
					meeting_type_id: "1",
				},
				report: item,
				client_id: item.client_id,
				meeting_id: item.meeting_id,
				is_visited: true,
				start_date: item.created_at,
				end_data: item.created_at,
				meeting_type_id: "1",
			};
		});
	};
	// concat offlineMeetings with meetinghistory
	const totalMeetings = useMemo(() => {
		if (
			(offlineMeetings.length ||
				offlineReports.length ||
				offlineUpdateMeeting.length) &&
			clientId &&
			user?.user_id
		) {
			// Filter offlineMeetings by clientId
			const currentClientsMeetings = offlineMeetings.filter(
				(item) => item?.client_id === clientId
			);
			let recurrentClientsReports = offlineReports.filter(
				(item) => item?.client_id === clientId
			);
			const formatList = formatReports(recurrentClientsReports);

			if (meetinghistory.length) {
				let mergeMeetings = meetinghistory.concat(
					currentClientsMeetings,
					formatList
				);
				if (offlineUpdateMeeting.length) {
					mergeMeetings = mergeMeetings.map((meeting) => {
						const updatedMeeting = offlineUpdateMeeting.find(
							(updatedMeeting) =>
								updatedMeeting.meeting_id === meeting.meeting_id &&
								updatedMeeting.client?.client_id === meeting.client_id
						);
						if (updatedMeeting) {
							return { ...meeting, ...updatedMeeting };
						}
						return meeting;
					});
				}
				return mergeMeetings.sort(
					(a, b) => new Date(a.start_date) - new Date(b.start_date)
				);
			}

			return currentClientsMeetings
				.concat(formatList)
				.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
		}
		return meetinghistory;
	}, [
		offlineMeetings,
		meetinghistory,
		offlineReports,
		offlineUpdateMeeting,
		clientId,
		user,
	]);
	const openModal = () => {
		setNewEventData({
			...newEventData,
			start: initialStartDate,
			end: initialEndDate,
		});
		setOpenModal(true); // This navigates back one step in the history
	};
	const closeModal = () => {
		setOpenModal(false); // This navigates back one step in the history
	};

	useEffect(() => {
		setLocale(localStorage.getItem("language"));
		const storedLayoutData = localStorage.getItem("layoutData");
		if (storedLayoutData) {
			try {
				const parsedData = JSON.parse(storedLayoutData);
				setUrl(parsedData); // Store the parsed object in state
			} catch (error) {
				console.error("Error parsing layoutData from localStorage:", error);
			}
		}
	}, []);

	const navigate = useNavigate();

	const handleGoBack = () => {
		navigate(-1); // This navigates back one step in the history
	};

	const handleStartVisit = () => {
		setToggle(true);
	};

	const handleSaveVisit = () => {
		setToggle(false);

		getClientInfo(clientId);
	};

	useEffect(() => {
		getUserName();
	}, []);

	useEffect(() => {
		// Scroll to the start of the target div when 'toggle' changes
		if (toggle && targetDivRef.current) {
			setTimeout(() => {
				targetDivRef.current.scrollIntoView({ behavior: "smooth" });
			}, 100); // Adjust the delay as needed a
		}
	}, [toggle, targetDivRef]);

	// Monitor whether the current user's recommendation information is updated offline
	const totalList = useMemo(() => {
		if (list.length) {
			if (offlineRecommendations.length) {
				return list.filter(
					(item) =>
						!offlineRecommendations.some(
							(recommendation) =>
								recommendation.client_id === item.client_id &&
								recommendation.task_id == item.task_id
						)
				);
			}
			return list;
		}
		return [];
	}, [list, offlineRecommendations]);

	useEffect(() => {
		if (user.user_id && clientId) {
			getClientInfo(clientId);

			getReportLayout();

			targetDivRef.current = document.getElementById("yourTargetDivId");
		}
	}, [user, clientId, isOnline]);

	const getUserName = () => {
		client.fetchUser().then((data) => {
			setUser(data);
		});
	};

	const getAllOfflineClients = () => {
		const storeName = CACHE_RESPONSE_DATA[CACHE_RESPONSE_API.CLIENTS].storeName;
		const url = `${config.apiBasePath}${CACHE_RESPONSE_API.CLIENTS}?user_id=${user.user_id}`;
		getDataFromStore(storeName, url)
			.then((result) => {
				if (result?.data?.length) {
					const currentClients = result?.data?.filter(
						(item) => item.client_id === clientId
					);
					handleClientDetailInfo(currentClients);
				}
			})
			.catch((error) => {
				console.error("Error fetching data:", error);
			});
	};

	const getClientInfo = (id) => {
		if (isOnline) {
			client.getUserClients(null, id).then((data) => {
				console.log("DATA", data);
				if (data.length === 1) {
					console.log(data[0].client_name); // Access client_name directly
				}
				handleClientDetailInfo(data);
			});
		} else {
			getAllOfflineClients();
		}
	};

	const handleClientDetailInfo = (data) => {
		setClientInfo(data[0]);
		setClientStats(data[0].statistics);
		setClientReceipts(data[0].receipts);
		setProductRecos(data[0].product_recommendations);
		setList(data[0].next_best_actions);
		setClientOrders(data[0].orders);
		setClientMeetingHistory(data[0].meetings);
		setRefreshing(false);
	};

	const getReportLayout = () => {
		client.getReportLayout().then((data) => {
			setReportLayout(data);
			console.log("Report Layouts", data);
		});
	};

	// const handleSendMail = async () => {
	//     try {
	//         // Attempt to send email
	//         await client.sendMail({
	//             to: "tobi@myway.technology",
	//             subject: `Demande de dépannage pour ${clientInfo.client_name}`,
	//             content: `Hello ${clientInfo.client_name},\n\nThis is a message sent from your application.`,
	//             client_id: clientId,
	//             user_id: user.user_id,
	//         });

	//         // If email is sent successfully
	//         setMessage("Email envoyé avec succès!"); // Success message in French
	//         setMessageType("success");

	//         // Automatically hide the message after 2 seconds
	//         setTimeout(() => {
	//             setMessage(null);
	//         }, 2000);
	//     } catch (error) {
	//         // If there's an error sending the email
	//         setMessage("Une erreur s'est produite. Veuillez réessayer."); // Error message in French
	//         setMessageType("error");

	//         // Automatically hide the message after 2 seconds
	//         setTimeout(() => {
	//             setMessage(null);
	//         }, 2000);
	//     }
	// };

	const [successMessage, setSuccessMessage] = useState(""); // Track success message
	useEffect(() => {
		if (successMessage) {
			const timer = setTimeout(() => setSuccessMessage(""), 3000); // Hide message after 3 seconds
			return () => clearTimeout(timer); // Cleanup on unmount
		}
	}, [successMessage]);

	const [errorMessage, setErrorMessage] = useState(""); // Track success message
	useEffect(() => {
		if (errorMessage) {
			const timer = setTimeout(() => setErrorMessage(""), 3000); // Hide message after 3 seconds
			return () => clearTimeout(timer); // Cleanup on unmount
		}
	}, [errorMessage]);

	if (refreshing) {
		return <Loader />; // Display Loader while data is being fetched
	}

	return (
		<section className="flex flex-row md:h-full h-[88%]">
			<div
				style={{
					flex: 1,
					overflowY: "auto",
				}}
			>
				<IntlProvider locale={locale} messages={messages[locale]}>
					<BackButton handleGoBack={handleGoBack} />

					<div className="flex flex-row items-center mt-5">
						<div className="xl:ml-10 pl-4 text-left text-black bg-white text-xl font-bold uppercase text-black">
							{clientInfo?.client_name},{" "}
							<span className="font-normal text-md">{clientInfo?.city}</span>
						</div>
					</div>
					<div className="xl:ml-10 pl-4 text-left text-gray-400 bg-white text-base font-bold uppercase text-black">
						ID: {clientInfo?.client_id}
					</div>
					<div className="flex flex-col md:flex-row w-full xl:pr-10 xl:pl-10 mt-4">
						<div className="float-left md:w-1/4 flex-col">
							<CollapseComponent
								text={
									<FormattedMessage
										id="client.client_details"
										values={{ number: 1 }}
									/>
								}
								icon={<FiBookOpen />}
								maximize={false}
								open={false}
								toggle={true}
							>
								{clientInfo && <CustomerDetails customer={clientInfo} />}
							</CollapseComponent>
							<CollapseComponent
								text={
									<FormattedMessage id="client.sales" values={{ number: 1 }} />
								}
								icon={<FiBarChart2 />}
								maximize={isMdOrLarger}
								open={true}
								toggle={true}
								statistics={clientstats}
								tooltipText={"Depuis le 1er janvier"}
							>
								<ClientStatistics statistics={clientstats} />
							</CollapseComponent>
						</div>

						<div className="flex flex-col float-left md:w-1/2">
							<CollapseComponent
								text={
									<FormattedMessage id="client.nba" values={{ number: 1 }} />
								}
								icon={<FiUsers />}
								maximize={false}
								open={true}
							>
								<ul className="display-inline text-gray-700 mb-5 text-sm w-5/6">
									{totalList.filter((item) => !item.read).length === 0 ? (
										<h1>
											<FormattedMessage id="client.notasks" />
										</h1>
									) : (
										totalList
											.filter((item) => !item.read) // Only include items where read is False
											.map((item) => (
												<List
													recommendation={item}
													key={item.task_id}
													getRecommendations={getClientInfo}
													clientInfo={clientInfo}
													client_id={clientId}
												/>
											))
									)}
								</ul>
							</CollapseComponent>
							<CollapseComponent
								text={
									<FormattedMessage
										id="client.products"
										values={{ number: 1 }}
									/>
								}
								icon={<FiShoppingCart />}
								maximize={false}
								toggle={true}
								open={true}
								tooltipText={"Produits commandés plus de 4 fois"}
							>
								<ClientOrders clientorders={clientorders} />
							</CollapseComponent>
							{!user.storemodule && (
								<CollapseComponent
									text={
										<FormattedMessage
											id="client.client_history"
											values={{ number: 1 }}
										/>
									}
									icon={<FiFileText />}
									maximize={false}
									toggle={true}
									open={false}
									tooltipText={"Dernières 4 factures"}
								>
									<OrderHistory orderhistory={clientreceipts} />
								</CollapseComponent>
							)}
							{!user.storemodule && (
								<CollapseComponent
									text={
										<FormattedMessage
											id="client.client_interactions"
											values={{ number: 1 }}
										/>
									}
									icon={<FiUsers />}
									maximize={false}
									toggle={true}
									open={false}
								>
									<VisitHistory
										visithistory={totalMeetings}
										reportLayout={reportlayout}
									/>
								</CollapseComponent>
							)}
						</div>

						<div className="flex flex-col overflow-hidden touch-none float-left md:w-1/4">
							<CollapseComponent
								text={
									<FormattedMessage
										id="client.product_reco"
										values={{ number: 1 }}
									/>
								}
								icon={<FiShuffle />}
								maximize={false}
								open={true}
							>
								{product.length > 0 &&
									product.map((prod) => (
										<Product product={prod} key={prod.product_id} />
									))}
								{
									<div className="pr-3 pl-4 text-md text-sm">
										<FormattedMessage
											id="client.no_product_reco"
											values={{ number: 1 }}
										/>
									</div>
								}
							</CollapseComponent>

							<div className="hidden md:block ">
								<div className="overflow-hidden w-full mb-3">
									<div className="ml-3 mr-3 md:mr-0 border border-gray-200 bg-white rounded-md">
										<div className="flex flex-row w-full pl-2 items-center mb-2 mt-2">
											<div className="flex w-8 h-8 bg-gray-100 ml-2 mt-2 rounded-full items-center justify-center">
												<FiMessageSquare />
											</div>
											<div className="flex text-lg md:text-base xl:text-lg text-black text-left ml-2 md:w-4/6 w-full bg-white mt-2">
												Chat
											</div>
											<div className="flex xl:w-1/6 md:w-1/6 sm:w-1/6 w-1/6 items-center mt-2"></div>
										</div>
										<div className="h-60">
											<Channel
												client_id={clientInfo.client_id}
												currentUser={user.user_id}
												clientName={clientInfo.client_name}
												header={false}
												isOnline={isOnline}
												messages={chatMessages}
												setMessages={setChatMessage}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className=""></div>

					{message && (
						<div
							className={`fixed bottom-10 left-1/2 transform -translate-x-1/2 p-4 rounded-md text-white ${
								messageType === "success" ? "bg-green-500" : "bg-red-500"
							}`}
							style={{ zIndex: 999 }}
						>
							{message}
						</div>
					)}

					{!toggle && (
						<div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 md:left-auto md:right-5 md:transform-none flex-col items-center pb-5 md:pb-0">
							<div className="flex flex-row w-full sm:mt-5 sm:mb-5 md:mb-2">
								<div className="w-2/3 md:w-5/6 bg-black"></div>

								<button
									onClick={() => handleStartVisit()}
									className="bg-gold rounded-full text-lg text-black rounded-full font-semibold p-5 drop-shadow-lg "
								>
									<FiEdit />
								</button>

								<button
									onClick={openModal}
									className="bg-black rounded-full text-lg text-gold font-semibold p-5 drop-shadow-lg ml-10 "
								>
									<FiCalendar />
								</button>
								<button
									onClick={() => {
										if (shopURL?.shop_url) {
											window.open(shopURL.shop_url, "_blank");
										} else {
											console.error(
												"shopURL.shop_url is missing or undefined!"
											);
										}
									}}
									className="bg-black rounded-full text-lg text-gold font-semibold p-5 drop-shadow-lg ml-10 "
								>
									<FiShoppingBag />
								</button>
								{/* <button
                                    onClick={handleSendMail}
                                    className="bg-black rounded-full text-lg text-gold font-semibold p-5 drop-shadow-lg ml-10"
                                >
                                    <FiAlertTriangle />
                                </button> */}
							</div>
						</div>
					)}
					<div id="yourTargetDivId"></div>
					{toggle && (
						<section className="w-full bg-white flex flex-col items-center border-t border-gray-100  mt-5">
							{reportlayout.length > 0 && (
								<ReportForm
									reportlayout={reportlayout}
									setToggle={handleSaveVisit}
									clientId={clientId}
									meetingId={meetingId}
									userId={user.user_id}
									userName={user.first_name}
									meeting_type_id={meeting_type_id}
									setErrorMessage={setMessage} // Success message in French
									setErrorMessageType={setMessageType}
								/>
							)}
						</section>
					)}
					{openmodal && (
						<section className="w-full bg-white flex flex-col items-center border-t border-gray-100  mt-5">
							<CreateMeetingModal
								closeModal={closeModal}
								locale={locale}
								user_id={user.user_id}
								initialEventData={newEventData}
								isEditing={false}
								user={user}
								setSuccessMessage={setSuccessMessage}
								setErrorMessage={setErrorMessage}
								is_client_meeting={true}
							/>
						</section>
					)}
					{successMessage && (
						<div className="fixed bottom-0 left-0 right-0 bg-green-500 text-white text-center py-2 z-50 mx-auto w-1/5 rounded-t-lg">
							{successMessage}
						</div>
					)}
					{errorMessage && (
						<div className="fixed bottom-0 left-0 right-0 bg-rose-500 text-white text-center py-2 z-50 mx-auto w-1/5 rounded-t-lg">
							{errorMessage}
						</div>
					)}
				</IntlProvider>
			</div>
		</section>
	);
};

export default ClientPage;
